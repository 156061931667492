<template>
  <textarea
    v-if="multiline"
    :readonly="readonly"
    :value="modelValue"
    ref="input"
    :class="$style.textarea"
    :placeholder="placeholder"
    :rows="1"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
  ></textarea>
  <input
    v-else
    :value="modelValue"
    :readonly="readonly"
    :placeholder="placeholder"
    ref="input"
    :class="$style.input"
    @input="onInput"
    @focus="onFocus"
    @blur="onBlur"
  />
</template>

<script>
import autosize from 'autosize'
import IMask from 'imask'
import debounce from 'lodash/debounce'

export default {
  name: 'UiInput',
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: ''
    },
    multiline: {
      type: Boolean,
      default: false
    },
    rows: {
      type: Number,
      default: 1
    },
    mask: {
      type: [String, Boolean, Object, Number, RegExp],
      default: ''
    },
    maskUppercase: {
      type: Boolean,
      default: false
    },
    debounce: {
      type: Number,
      default: 1
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  emits: ['focus', 'blur', 'update:modelValue'],
  data() {
    return {
      inputmask: null,
      focused: false,
      touched: false
    }
  },
  computed: {
    changeDebounce() {
      return debounce(this.change, this.debounce)
    }
  },
  methods: {
    checkAutosize() {
      if (this.multiline) {
        autosize(this.$refs.input)
      } else {
        autosize.destroy(this.$refs.input)
      }
    },
    onInput() {
      this.changeDebounce()
    },
    onFocus() {
      this.$emit('focus')
    },
    onBlur() {
      this.$emit('blur')
    },
    clear() {
      this.changeDebounce('')
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus()
      })
    },
    change() {
      this.$emit('update:modelValue', this.$refs.input.value)
    },
    setMask() {
      // https://imask.js.org/
      // https://imask.js.org/guide.html
      let options
      if (typeof this.mask === 'object') {
        options = { ...this.mask }
      } else {
        options.mask = this.mask
      }
      if (this.maskUppercase) {
        options.prepare = str => str.toUpperCase()
      }
      this.inputmask = IMask(this.$refs.input, options)
    }
  },
  mounted() {
    this.checkAutosize()
    if (this.mask) {
      this.setMask()
    }
  },
  beforeUnmount() {
    if (this.multiline) {
      autosize.destroy(this.$refs.input)
    }
    if (this.inputmask) {
      this.inputmask.destroy()
    }
  },
  watch: {
    multiline() {
      this.checkAutosize()
    }
  }
}
</script>

<style lang="scss" module>
.input,
.textarea {
  resize: none;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0 none;
  outline: none;
  width: 100%;
  border-radius: 0;
  &::placeholder {
    opacity: 1;
  }
}
</style>
